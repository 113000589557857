import { useContext } from "react"
import { AppContext } from "../App"
import { STORAGEURL } from "..";
import { At } from "@phosphor-icons/react";

const Tajemnice = () => {
    const {contact_title, contact_text, contact_name, contact_email, contact_photo} = useContext(AppContext);
    return (
        <section className="py-top px-2vw bg-base-color flex tablet:flex-col tablet:text-center justify-between items-center gap-vw text-white">
            <div className="halffull"><h2 className="max-w-56 tablet:max-w-full">{contact_title}</h2></div>
            <div className="halffull flex tablet:flex-col items-center pt-5 gap-8">
                <img src={STORAGEURL+contact_photo} alt='Fotka tajemnice' className="h-32 aspect-square object-cover rounded-full" />
                <div>
                    <p className="font-semibold pb-4">{contact_text}</p>
                    <b>{contact_name}</b>
                    <p className="flex gap-2 items-center tablet:justify-center pt-2"><At className="text-xl" /><a href={'mailto:'+contact_email}>{contact_email}</a></p>
                </div>
            </div>
        </section>
    )
}

export default Tajemnice