import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectCoverflow, Navigation } from 'swiper/modules';
import { APIURL, STORAGEURL } from "..";
import FsLightbox from "fslightbox-react";
import ReactPlayer from 'react-player';

import 'swiper/css';
import 'swiper/css/navigation';

const GallerySwiper = () => {
    const [data, setData] = useState();
    const [srcs, setSrcs] = useState(null);
    const [slide, setSlide] = useState(null);
    const [lightbox, setLightbox] = useState(false);
    let isDesktop = window.innerWidth>1000;

    const getData = async () => {
        await axios.post(APIURL+'/collection/items', {type: 'gallery', orderAsc: 'ASC', limit: 100, page: 1,language: 'cs'}).then(response =>{
            let resp = response.data.body.items;
            let max = isDesktop?6:3;
            if (resp.length<max) {
                setData(resp.concat(resp));
            } else {
                setData(resp);
            }
            setSrcs(resp.map(i=>(i.url?i.url:(STORAGEURL+i.src))));
        })
    }
    useEffect(()=>{
        getData();
    }, [])
    if (data) return (
        <section className="py-2top px-vw">
            <Swiper
                modules={[Autoplay,EffectCoverflow,Navigation]}
                // autoplay={true}
                loop={true}
                effect="coverflow"
                coverflowEffect={{depth: 200, rotate:0,slideShadows:false}}
                slidesPerView={isDesktop?3:1}
                spaceBetween={50}
                navigation
                grabCursor={true}
                className="gallerySwiper"
            >
                {data.map((file,i)=>(
                    <SwiperSlide key={file.id+i} className="!h-[unset]">
                        <div className="gallerySlide" onClick={() => {setSlide(file.url?file.url:file.src);setLightbox(!lightbox)}}>
                            {file.url?<div className="video yt"><ReactPlayer url={file.url} /></div>:(/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i).test(file.src)?<img src={STORAGEURL+file.src} alt={file.name} />: <div className="video btn"><ReactPlayer url={STORAGEURL+file.src} /></div>}
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
            {srcs&&<FsLightbox
				toggler={lightbox}
				sources={srcs}
                source={STORAGEURL+slide}
			/>}
        </section>
    )
}

export default GallerySwiper;