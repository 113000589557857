import { useLenis } from "@studio-freight/react-lenis";
import { useEffect, useRef, useState } from "react";

const Tabs = ({value, onChange=()=>{}}) => {
    const [val, setVal] = useState(false);
    const [fixed, setFixed] = useState(false);
    const fullsize = window.innerWidth>700;
    const lenis = useLenis();
    useEffect(()=>{
        lenis?.on('scroll', (e) => {
            setFixed(document.getElementById('tabs-container')?.getBoundingClientRect().top<0)
        })
    }, [lenis])
    useEffect(()=>{
        fixed&&lenis?.scrollTo(document.getElementById("zeptejse"), {offset:-100});
        onChange(val);
    }, [val])
    return (
        <div className="min-h-16" id='tabs-container'>
            <label
            id="toggle-tabs"
            className={"flex justify-center items-center cursor-pointer "+(fixed?'h-32 w-16 fixed right-4 bottom-4 z-50 ':fullsize?"h-16 w-[32rem] relative px-4 ":'h-16 w-36 relative ')+"rounded-full bg-white ring-1 ring-inset ring-zinc-400 shadow mx-auto"}>
            <input
                className="peer sr-only "
                type="checkbox"
                value={val}
                onChange={()=>{setVal(!val)}}
                aria-labelledby="toggle-tabs-label"
            />
            {fixed?<>
                <span className="absolute top-[1.4em] top-1/2 flex items-center z-10 peer-checked:text-zinc-500 text-white justify-center">Bc.</span> 
                <span className="absolute bottom-[.7em] top-1/2 flex items-center text-zinc-500 z-10 peer-checked:text-white">Ing.</span> 
            <span
                id="toggle-tabs-label"
                className="absolute top-[0.45em] h-14 w-14 top-1/2 flex size-10 items-center justify-center rounded-full bg-orange-600 to-zinc-400 transition-all duration-300 peer-checked:top-[calc(100%-4.0em)] "
                aria-hidden="true"
            ></span>
            </>:fullsize?<>
                <span className="absolute uppercase left-8 top-1/2 -translate-y-1/2 flex items-center z-10 peer-checked:text-zinc-500 text-white w-52 justify-center">
                    Bakalářské programy
                </span>
                <span className="absolute uppercase right-8 top-1/2 -translate-y-1/2 flex items-center text-zinc-500 z-10 peer-checked:text-white w-52 justify-center">
                    Magisterské programy
                </span>
                <span
                    id="horizontal-toggle-label"
                    className="absolute left-4 w-60 h-12 top-1/2 -translate-y-1/2 flex items-center justify-center rounded-full bg-orange-600 transition-all duration-300 peer-checked:left-[calc(100%-16rem)]"
                    aria-hidden="true"
                ></span>
            </>:<>
                <span className="absolute left-[1.8em] top-1/2 flex  -translate-y-1/2 items-center justify-center z-10 peer-checked:text-zinc-500 text-white">Bc.</span>
                <span className="absolute right-[1.6em] top-1/2  -translate-y-1/2 rounded-full text-zinc-500 z-10 peer-checked:text-white">Ing.</span> 
                <span
                    id="horizontal-toggle-label"
                    className="absolute left-[0.45em] w-16 h-12 top-1/2 flex size-10 -translate-y-1/2 items-center justify-center rounded-full bg-orange-600 to-zinc-400 transition-all duration-300 peer-checked:left-[calc(100%-4.45em)] "
                    aria-hidden="true"
                ></span>
            </>}
            </label> 
        </div>
    )
}

export default Tabs;