import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCards, Navigation } from 'swiper/modules';
import {Info, FacebookLogo,InstagramLogo,At } from "@phosphor-icons/react";
import 'swiper/css';
import 'swiper/css/effect-cards';
import "swiper/css/navigation"
import "/node_modules/flag-icons/css/flag-icons.min.css";
import Modal from './UI/Modal';
import { useContext, useState } from 'react';
import parse from 'html-react-parser';
import { STORAGEURL } from '..';
import { AppContext } from '../App';

const PhotoSwiper = ({data}) => {
    const [modal, setModal] = useState(null);
    const {contactme, translation, istudy, ispeak} = useContext(AppContext);
    return (
        <>
            <Swiper
                effect={'cards'}
                cardsEffect={{perSlideRotate:8}}
                grabCursor={true}
                initialSlide={1}
                navigation
                modules={[EffectCards, Navigation]}
                className="photoSwiper"
            >
                {data?.map(card=><SwiperSlide key={card.id} className='p-4 shadow-swiper'>
                    <div onClick={()=>setModal(card)}>
                        <img src={STORAGEURL+card.photo} className='w-full aspect-square object-cover object-top' alt='Fotka studenta/ky' />
                        <div className='absolute top-4 left-4'>
                            {card.langs.replace(/\s/g, '').split(',').map((l,i,a)=><span key={i} className={"fi fi-"+l+" fis text-4xl tablet:text-xl -mx-2 tablet:-mr-1 rounded-full"} style={{zIndex:a.length-i}}></span>)}
                        </div>
                        <Info className='absolute text-4xl right-4 top-4 mobil:text-xl mobil:top-3 cursor-pointer' />
                    </div>
                    <div className='py-4 text-center'>
                        <h4 className='CoveredByYourGrace' onClick={()=>setModal(card)}>{card.name}</h4>
                        <div className='pt-2 flex justify-center items-center gap-2 text-base-color text-3xl'>
                            <span className='text-lg tablet:text-base uppercase font-bold pr-2'>{contactme||'Napiš mi!'}</span>
                            {card.fb&&<a href={card.fb} target='_blank' ><FacebookLogo /></a>}
                            {card.ig&&<a href={card.ig} target='_blank'><InstagramLogo  /></a>}
                            {card.email&&<a href={"mailto:"+card.email} ><At /></a>}
                        </div>
                    </div>
                </SwiperSlide>)}
            </Swiper>
            {modal&&<Modal state={[modal, setModal]} >
                <div className='w-[80vw] max-w-[1100px] aspect-video flex tablet:aspect-auto tablet:flex-col-reverse'>
                    <div className='halffull border-r border-black pr-8 flex flex-col justify-between tablet:border-none tablet:pr-0'>
                        <p className='CoveredByYourGrace text-2xl pb-2 mobil:text-xl parsedDiv'>{parse(modal.message)}</p>
                        <div className='flex items-center gap-2 text-base-color text-3xl'>
                            <span className='text-lg uppercase font-bold pr-2'>{contactme||'Napiš mi!'}</span>
                            {modal.fb&&<a href={modal.fb} target='_blank' ><FacebookLogo /></a>}
                            {modal.ig&&<a href={modal.ig} target='_blank'><InstagramLogo  /></a>}
                            {modal.email&&<a href={"mailto:"+modal.email} ><At /></a>}
                        </div>
                    </div>
                    <div className='halffull pl-8 CoveredByYourGrace text-2xl flex flex-col items-start dopis tablet:pl-0 tablet:pb-8'>
                        <img src={STORAGEURL+modal.photo} className='w-1/2 self-end tablet:self-start aspect-square object-cover object-top' alt='Fotka studenta/ky' />
                        <p className='mt-auto tablet:pt-8'>{modal.name}</p>
                        <p>{istudy||'Studuju'} {modal.program}</p>
                        {modal.langs_full? <p>
                            {ispeak||'Mluvím'} {modal.langs_full.split(',').map(l=>l.trim()).filter(Boolean).join(", ")}
                        </p> : <p>
                            {ispeak||'Mluvím'} {modal.langs?.replace(/\s/g, '').toLowerCase().split(',').map(l=>jazyky[translation][l]).filter(Boolean).join(", ")}
                        </p>}
                    </div>
                </div>
            </Modal>}
        </>
    )
}

export default PhotoSwiper;



const jazyky = {
    cs: {
        gb: 'anglicky',
        us: 'anglicky',
        fr: 'francouzsky',
        es: 'španělsky',
        ru: 'rusky',
        pt: 'portugalsky',
        de: 'německy',
        dz: 'arabsky',
        eg: 'arabsky',
        sa: 'arabsky',
        jp: 'japonsky',
        kr: 'korejsky',
        tr: 'turecky',
        it: 'italsky',
        vn: 'vietnamsky',
        th: 'thajsky',
        nl: 'nizozemsky',
        se: 'švédsky',
        no: 'norsky',
        dk: 'dánsky',
        fi: 'finsky',
        pl: 'polsky',
        cz: 'česky',
        sk: 'slovensky',
        hu: 'maďarsky',
        ro: 'rumunsky',
        bg: 'bulharsky',
        gr: 'řecky',
        ua: 'ukrajinsky',
        cn: 'čínsky',
        tw: 'čínsky',
    },
    en: {
        gb: 'English',
        us: 'English',
        fr: 'French',
        es: 'Spanish',
        ru: 'Russian',
        pt: 'Portuguese',
        de: 'German',
        dz: 'Arabic',
        eg: 'Arabic',
        sa: 'Arabic',
        jp: 'Japanese',
        kr: 'Korean',
        tr: 'Turkish',
        it: 'Italian',
        vn: 'Vietnamese',
        th: 'Thai',
        nl: 'Dutch',
        se: 'Swedish',
        no: 'Norwegian',
        dk: 'Danish',
        fi: 'Finnish',
        pl: 'Polish',
        cz: 'Czech',
        sk: 'Slovak',
        hu: 'Hungarian',
        ro: 'Romanian',
        bg: 'Bulgarian',
        gr: 'Greek',
        ua: 'Ukrainian',
        cn: 'Chinese',
        tw: 'Chinese',
    },
    de: {
        gb: 'Englisch',
        us: 'Englisch',
        fr: 'Französisch',
        es: 'Spanisch',
        ru: 'Russisch',
        pt: 'Portugiesisch',
        de: 'Deutsch',
        dz: 'Arabisch',
        eg: 'Arabisch',
        sa: 'Arabisch',
        jp: 'Japanisch',
        kr: 'Koreanisch',
        tr: 'Türkisch',
        it: 'Italienisch',
        vn: 'Vietnamesisch',
        th: 'Thailändisch',
        nl: 'Niederländisch',
        se: 'Schwedisch',
        no: 'Norwegisch',
        dk: 'Dänisch',
        fi: 'Finnisch',
        pl: 'Polnisch',
        cz: 'Tschechisch',
        sk: 'Slowakisch',
        hu: 'Ungarisch',
        ro: 'Rumänisch',
        bg: 'Bulgarisch',
        gr: 'Griechisch',
        ua: 'Ukrainisch',
        cn: 'Chinesisch',
        tw: 'Chinesisch',
    },
    es: {
        gb: 'Inglés',
        us: 'Inglés',
        fr: 'Francés',
        es: 'Español',
        ru: 'Ruso',
        pt: 'Portugués',
        de: 'Alemán',
        dz: 'Árabe',
        eg: 'Árabe',
        sa: 'Árabe',
        jp: 'Japonés',
        kr: 'Coreano',
        tr: 'Turco',
        it: 'Italiano',
        vn: 'Vietnamita',
        th: 'Tailandés',
        nl: 'Neerlandés',
        se: 'Sueco',
        no: 'Noruego',
        dk: 'Danés',
        fi: 'Finlandés',
        pl: 'Polaco',
        cz: 'Checo',
        sk: 'Eslovaco',
        hu: 'Húngaro',
        ro: 'Rumano',
        bg: 'Búlgaro',
        gr: 'Griego',
        ua: 'Ucraniano',
        cn: 'Chino',
        tw: 'Chino',
    }
};
