import './Modal.scss';
import {CSSTransition} from 'react-transition-group';
import { useEffect, useRef } from 'react';
import { useLenis } from '@studio-freight/react-lenis';

const Modal = ({children, state, className=''}) => {
    const [modal, setModal] = state;
    const nodeRef = useRef(null);
    const lenis = useLenis();
    useEffect(()=>{
        if (lenis) {
            if (modal) {
                lenis.stop();
            } else {
                lenis.start();
            }
        }
        // console.log(lenis,modal)
    }, [modal, lenis])
    const close = () => {
        lenis.start();
        setModal(null);
    }

    return (
        <CSSTransition in={modal} ref={nodeRef} timeout={300} unmountOnExit={true} appear={true} classNames='modal'  >
            <div className="supermodal" data-lenis-prevent>
                <div className='supermodal__bg' onClick={close}></div>
                <div className={"supermodal__box "+className}>
                    <button className='closeModal' onClick={close}>&times;</button>
                    {children}                
                </div>
            </div>
        </CSSTransition>
    )
}
export default Modal;