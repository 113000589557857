import axios from 'axios';
import parse from 'html-react-parser';
import { useContext, useEffect, useState } from 'react';
import { APIURL, STORAGEURL } from '..';
import { AppContext } from '../App';

const Grid = () => {
    const [data, setData] = useState();
    const {language, readmore} = useContext(AppContext);

    const getData = async () => {
        await axios.post(APIURL+'/collection/items', {type: 'sections_'+language, orderAsc: 'ASC', limit: 100, page: 1,language: 'cs'}).then(response =>{
          setData(response.data.body.items);
        })
      }
    
      useEffect(()=>{
        getData();
      }, [])
    return data?.map((section,i) => (
        <section key={section.id} className={`py-top px-2vw flex gap-vw ${i%2===0?'tablet:flex-col':'flex-row-reverse tablet:flex-col-reverse'}`}>
            <img src={STORAGEURL+section.img} alt='FMV section img' className='halffull h-full max-h-[650px] object-cover tablet:h-auto' />
            <div className='halffull'>
                <h2>{section.name}</h2>
                <div className="parsedDiv !py-4">{parse(section.text)}</div>
                {section.link&&<a href={section.link} className='button' target="_blank">{readmore}</a>}
                {section.note&&<small className='block pt-4 text-gray-400'>* {section.note}</small>}
            </div>
        </section>
    ))
}
export default Grid